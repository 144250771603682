import Swiper, { Navigation, Thumbs, FreeMode, Scrollbar } from 'swiper';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
// import 'swiper/css/navigation';

const thumbsSwiper = new Swiper('.product-vertical-slider-js', {
  modules: [Navigation, Thumbs, FreeMode],
  slidesPerView: 4,
  direction: 'vertical',
  freeMode: true,
  watchSlidesProgress: true,
  autoHeight: true,
  spaceBetween: 16,
  navigation: {
    enabled: true,
    nextEl: '.product-vertical-slider__next_js',
    prevEl: '.product-vertical-slider__prev_js',
  },
  // breakpoints: {
  //   // when window width is >= 320px
  //   320: {
  //     slidesPerView: 2,
  //     spaceBetween: 20,
  //   },
  //   // when window width is >= 480px
  //   768: {
  //     slidesPerView: 4,
  //     spaceBetween: 8,
  //   },
  //   // when window width is >= 640px
  //   992: {
  //     // slidesPerView: 4,
  //     spaceBetween: 40,
  //   },
  // },
});

new Swiper('.product-main-slider-js', {
  modules: [Thumbs, Scrollbar],
  slidesPerView: 1,
  navigation: {
    nextEl: '.product-slider__next_js',
    prevEl: '.product-slider__prev_js',
  },
  thumbs: {
    swiper: thumbsSwiper,
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  breakpoints: {
    992: {
      scrollbar: false,
    },
  },
});

document.querySelector('.slider-full-size')?.addEventListener('click', () => {
  const link = document.querySelector('.product-main-slider-js  a') as HTMLAnchorElement;
  link?.click();
});
