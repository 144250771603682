import Swiper, { Navigation } from 'swiper';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
// import 'swiper/css/navigation';

new Swiper('.product-slider-js', {
  modules: [Navigation],
  slidesPerView: 4,
  navigation: {
    // enabled: true,
    nextEl: '.product-slider__next_js',
    prevEl: '.product-slider__prev_js',
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // when window width is >= 480px
    768: {
      slidesPerView: 4,
      spaceBetween: 8,
    },
    // when window width is >= 640px
    992: {
      // slidesPerView: 4,
      spaceBetween: 40,
    },
  },
});
