<template>
  <div class="test-swipe-modal">TestSwipeModal</div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.test-swipe-modal {
}
</style>
